import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Login from './Login';
import Shared from './Shared';

/*import About from './pages/about';
import Events from './pages/events';
import AnnualReport from './pages/annual';
import Teams from './pages/team';
import Blogs from './pages/blogs';
import SignUp from './pages/signup';
*/
function App() {
    //const [token, setToken] = React.useState(sessionStorage.getItem('token')|| null);
    const [token, setToken] = React.useState(JSON.parse(sessionStorage.getItem('token'))|| null);

    //const tokenn = JSON.parse(token)
    //const isauthenticated = (tokenn && tokenn.id);
    //alert(`isauthenticated= ${isauthenticated}`);
return (
	<Router>
	
	<Routes>
        {/*}
		<Route path="/" exact component={Home} />
        <Route path="/login" component={Login} />
        */}
        <Route index element={<Home token={token} setToken={setToken}/>} />
        <Route path="login" element={<Login token={token} setToken={setToken}/>} />
        <Route path="shared/:username/:id" element={<Shared/>} />
        
        
        
        {/*
		<Route path='/about' component={About} />
		<Route path='/events' component={Events} />
		<Route path='/annual' component={AnnualReport} />
		<Route path='/team' component={Teams} />
		<Route path='/blogs' component={Blogs} />
		<Route path='/sign-up' component={SignUp} />
        */}
	</Routes>
	</Router>
);
}

export default App;
