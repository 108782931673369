import React,{ useState }  from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, Navigate } from "react-router-dom";
import {
  Checkbox,
  Grid,
  TextField,
  FormControlLabel,
  Paper,
  Button
} from '@material-ui/core';
 import globals from "./globals"

//const api_root = "https://uploads.peerxc.com"
async function loginUser(username,password) {
    //alert('16: loginUser');
    const  login_url = `${globals.api_url}/account/login`
    //alert(`16: login_url= ${login_url}`);
    
    return fetch(login_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username:username,password:password})
    })
      .then(data => data.json())
   }

const LoginPage = ({token,setToken}) => {
  
  
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    //const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));
    //const [token, setToken] = useState(localStorage.getItem(localStorage.getItem("token")|| null));
    //const [token, setToken] = useState(localStorage.getItem(localStorage.getItem("token")|| null));
    //const [token, setToken] = useState(sessionStorage.getItem('token')|| null);
    //const [token, setToken] = useState(null);
    
    //const users = [{ username: "jane", password: "testpassword" }];
    const handleSubmit = async (e) => {
        //alert(`33: handleSubmit`);
    e.preventDefault();
    //const account = users.find((user) => user.username === username);
    //if (account && account.password === password) {
      sessionStorage.removeItem('token');
      setToken(null)
      
    const ret_token = await loginUser(username,password);
    //alert(`37: ret_token= ${JSON.stringify(ret_token,4,null)}`);
    
    if(ret_token && ret_token.username){
    
        //alert('25:');
        //setauthenticated(true);
        sessionStorage.setItem('token', JSON.stringify(ret_token));
        //setToken(JSON.stringify(ret_token))
        setToken({...ret_token})
        //localStorage.setItem("authenticated", true);
        //localStorage.setItem("token", JSON.stringify(token));
        //navigate("/dashboard");
        navigate("/");
        //return (<Navigate to="/" />);
    }
    else{
        alert('26:');
    }
    };
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    //alert('33: ');
    setChecked(event.target.checked);
  };

  return (
    <div style={{ padding: 30 }}>
      <Paper>
        <Grid
          container
          spacing={3}
          direction={'column'}
          justify={'center'}
          alignItems={'center'}
        >
          <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>PXC Cloud v1.0</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField name="username" onChange={e => setUsername(e.target.value)} label="Username"></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField name="password" onChange={e => setPassword(e.target.value)} label="Password" type={'password'}></TextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  label={'Keep me logged in'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Keep me logged in"
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleSubmit} fullWidth> Login </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default LoginPage;
