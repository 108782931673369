



// react-router-dom components
import { Link, useNavigate, Navigate } from "react-router-dom";

import { useState } from "react";
import { useParams } from "react-router-dom";




export default function Shared() {
  
  //sessionStorage.clear();
  //return (<Navigate to="/authenticate/sign-in"/>);
  const routeParams = useParams();

  const handleOpenFileOrDirOnClick = (fd) =>{
    const fd_path = fd.url_path.replace('//','/')
    //fd_path = fd_path.replace('//','/');
    //alert(`dir_path= ${dir_path}`);
    /*if(fd.type === 'folder'){
        fetch(`${globals.api_root}/open`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token.id}`},
        //body: JSON.stringify({username:token.username, token:token.id, dir:fd_path})
        body: JSON.stringify({username:token.username, token:token.id, id:fd.id})
        })
        .then(response => response.json())
        .then(json => {
            
            
            setDirFiles(json.data);
            setPathh({type:'folder', path:fd_path, id: fd.id, url_path: fd_path});
            //setIsLoaded(true);
        });
    }
    else if(fd.type === 'file'){*/
        //fetch(`${globals.api_root}/open`,{
        fetch(`${globals.api_root}/file`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token.id}`
    },
        //body: JSON.stringify({username:token.username, token:token.id, dir:fd_path})
        body: JSON.stringify({id:fd.id})
        })
        
        //.then(response => response.json())
        .then(response => {

            if (response.ok) {
                //alert('641:');
                return response.blob()
                    .then(function (myBlob) {
                        return {
                            blob: myBlob
                        };
                    });
            } else {
                //alert('649:');
                return response.json()
                    .then(function (myJson) {
                        return {
                            json: myJson
                        };
                    });
            }

        })
        .then(data => {
            
            if(data.blob){
                //alert('662');
                // Handle blob case
                const fileURL = URL.createObjectURL(data.blob);
                window.open(fileURL);

            }else{
                // Handle JSON case
            }
        }
        );
    //}


}

  //const [id, setId] = useState()
  alert(`routeParams= ${JSON.stringify(routeParams,null,4)}`)
  

  
  return(<div><p>Loading...</p></div>)
  
}

//export default Login;
/*
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
*/