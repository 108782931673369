import React from 'react';
import ReactDOM from 'react-dom/client';
//import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.css';
import App from './App';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './Theme';
//import { createTheme } from '@material-ui/core/styles'
//import Login from "./Login";
//import Dashboard from "./Dashboard";
import reportWebVitals from './reportWebVitals';
//import InteractiveList from './InteractiveList';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  }
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();






