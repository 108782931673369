//import React from 'react';
import * as React from 'react';

import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import globals from '../globals';
import { useNavigate, Navigate } from "react-router-dom";
//import NavBar from '../components/NavBar';

import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import Chip from '@mui/material/Chip'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';


import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TablePagination from '@mui/material/TablePagination';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


//import axios from 'axios';
//import {Progress} from 'reactstrap';
//import { FormControl } from '@mui/material';
import './index.css';



//async function removeFileOrDir(id,fpath,token) {
//async function removeFileOrDir(id,token) {
//async function removeFileOrDir(id,fdpath,fdtype,token) {
    async function removeFileOrDir(item,token) {
    //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
    
    //const post_data = { session:{sessionId: token}, data:{id:id, fpath:fpath}}
    const post_data = {username:token.username,token:token.id,fdpath:item.url_path,fdtype:item.type}//{ session:{sessionId: token}, data:{id:id}}
    //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
    //return fetch(`${globals.api_root}/cases/removefileordir`
    return fetch(`${globals.api_root}/trash`
    , {
      method: 'POST',
      headers: {

        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.id}`
      },
      body: JSON.stringify(post_data)
      
    }
    )
      .then(data => {
        
        return data.json();
      })
   }
/*
async function addDir(newpath,parentid,token,username) {
    //alert(`55: submitData(input_data= ${JSON.stringify(input_data)} token= ${JSON.stringify(token)})`);
    
    //const post_data = { session:{sessionId: token}, data:{id:id, fpath:fpath}}
    const post_data = { token_id: token_id, data:{id:id}}
    //alert(`57: post_data= ${JSON.stringify(post_data,null,4)}`)
    //return fetch(`${globals.api_root}/cases/removefileordir`
    return fetch(`${globals.api_root}/add-dir`
    , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(post_data)
      
    }
    )
      .then(data => {
        
        return data.json();
      })
   }
   */
/*
const getFileOwner = (fpath,user,callback)=>{
    console.log(`109 getFileOwner( fpath= ${fpath}, user= ${JSON.stringify(user,null,4)}`)
    filesTable.findOne({path:fpath, 'meta.username':user.username},(error,fileresult) =>{
        console.log(`111: error= ${JSON.stringify(error,null,4)}`)
        console.log(`112: fileresult= ${JSON.stringify(fileresult,null,4)}`)
        if(fileresult && fileresult.filename){
            return callback(null)
        }
        
        callback('ERROR 116 NOT owner???')

    })
}
*/
/*
function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
*/
/*
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
*/

const Home = ({token,setToken}) => {

    const MAX_COUNT = 10;
    const navigate = useNavigate();
    const [dense, setDense] = React.useState(true);
  const [secondary, setSecondary] = React.useState(false);
  const [inputs,setInputs] = React.useState({});
  const [files,setFiles] = React.useState([]);
  const [file,setFile] = React.useState([]);
  const [urll,setUrll] = React.useState([]);
  const [uploadfile,setUploadFile] = React.useState({selectedFile: null,loaded: 0});
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [uploadingFiles, setUploadingFiles] = React.useState([]);
  const [fileLimit, setFileLimit] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [isdeleting, setIsDeleting] = React.useState(false);
  const [pathh,setPathh] = React.useState({path:'/',type:'folder'})
  //const [dirfiles,setDirFiles] = React.useState([])
  const [folder,setFolder] = React.useState([])
  
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const ref = React.useRef<HTMLDivElement>(null);
  const [inputdir, setInputDir] = React.useState('');
  const [inputsdir, setInputsDir] = React.useState({ispublic:false});
  
  const [inputsmeta,setInputsMeta] = React.useState({});
  
    //const [expanded,setExpanded] = React.useState(false);
    //const [expanded, setExpanded] = React.useState(false);
    const [expandAddFolder, setExpandAddFolder] = React.useState(false);
    const [expandAddFile, setExpandAddFile] = React.useState(false);
  
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
  
      const [anchorElFileMenu, setAnchorElFileMenu] = React.useState(null);

      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(100);
      const [clipboardText, setClipboardText] = React.useState(null);

      const [isOpenClipboardDialog, setIsOpenClipboardDialog] = React.useState(false);
      //const [selectedValue, setSelectedValue] = React.useState(emails[1]);

      /*
      const handleClickOpenClipboardDialog = () => {
        setOpenClipboardDialog(true);
      };
      */

      const handleCloseClipboardDialog = (value) => {
        
        setIsOpenClipboardDialog(false);

        if(clipboardText){
          navigator.clipboard
                .writeText(clipboardText)
                .then(() => {
                  //alert("Copied to clipboard");
                })
                .catch((e) => {
                 // alert(`Copy failed ${e}`);
                  //setClipboardText(clipboardText)
                });
                      
                
        }
        //setSelectedValue(value);
        //setClipboardText(null)
      };

      const handleChangePage = (event, newPage) => {
        //setPage(newPage);
        
        handleNewPageOnClick(pathh.path,newPage);
      };

      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const openFileMenu = Boolean(anchorElFileMenu);
      const handleFileMenuClick = (event) => {
        setAnchorElFileMenu(event.currentTarget);
      };
      const handleFileMenuClose = () => {
        setAnchorElFileMenu(null);
      };

      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setDrawerState({ ...drawerState, [anchor]: open });
      };
  
  
      //const [token, setToken] = React.useState(sessionStorage.getItem('token')|| null);

  const url_dir = `${globals.api_url}/dir`;
  const url_fil = `${globals.api_url}/fil`;
  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };

  const [anchorEl, setAnchorEl] = React.useState(null | HTMLElement>(null));
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState(null | HTMLElement>(null));

    const [dragActive, setDragActive] = React.useState(false);
  
  const inputRef = React.useRef(null);

  
  const toggleAddFolderAccordion = () => {
      if(expandAddFile){
          toggleAddFileAccordion();
      }
    setExpandAddFolder((prev) => !prev);
  };

  const toggleAddFileAccordion = () => {
      if(expandAddFolder){
          toggleAddFolderAccordion();
      }
    setExpandAddFile((prev) => !prev);
  };

  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e) {
      //alert('handleDrop')
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    //if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    if (e.dataTransfer.files) {
      
     //startUpload(e.dataTransfer.files[0]);
     const chosenFiles = Array.prototype.slice.call(e.dataTransfer.files);
    handleUploadFiles(chosenFiles);
    }
  };
  
  const handleRemoveUploadedFiles = (file) =>{
    
    //let tmp_files = [...uploadedFiles];
    //var array = [...uploadedFiles]; // make a separate copy of the array
    //alert(`282: len= ${uploadedFiles.length}`);
    let array  =     uploadedFiles.filter(f => f!== file)
    //alert(`284:len= ${array.length}`);
   
    
    
    setUploadedFiles(array);
    

  }
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    //if (e.target.files && e.target.files[0]) {
    if (e.target.files && e.target.files[0]) {
    //if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.target.files);
      setUploadFile({
        selectedFile: e.target.files[0],//event.target.files[0],
        loaded: 0,
      });
      //alert( `218: uploadfile= ${JSON.stringify(uploadfile)}`);
      //startUpload();
    }
    else{
        alert('223: FAILED');
    }
  };

  const handleChangeMetaData = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //alert(`name= ${name} value= ${value}`)
    /*if(name == 'doctor'){
      const selected_doctor = doctors.find(d => d.id==value)
      setInputs(values => ({...values, doctor:{...values.doctor,} [name]: value}))
    
    }
    else{*/
      setInputsMeta(values => ({...values, [name]: value}))
    //}
  }
  
  const handleInputsDirChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    
    if(name === 'ispublic'){
        
        //alert(`name= ${name} e.target.checked=${e.target.checked}`);
        setInputsDir(values => ({...values, [name]: e.target.checked}));
    }
    else{
        setInputsDir(values => ({...values, [name]: value}));
    }
    
    
  }

// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };


  
  const handleFileEvent =  (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
    
}

const handleUploadFiles = files => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
            uploaded.push(file);
            if (uploaded.length === MAX_COUNT) setFileLimit(true);
            if (uploaded.length > MAX_COUNT) {
                alert(`Max of ${MAX_COUNT} files`)
                setFileLimit(false);
                limitExceeded = true;
                return true;
            }
        }
        
    })
    if(!limitExceeded) setUploadedFiles(uploaded);
    
 }
  /*
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
 
  */
  const listDrawerMenu = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <List>
        
          <ListItem key="Time Machine" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary="Time Machine" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key="Signout" disablePadding >
            <ListItemButton onClick={()=>{sessionStorage.clear(); setToken(null); navigate('/login');}}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Signout" />
            </ListItemButton>
          </ListItem>
        
      </List>
      
      {/*
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
        */}
    </Box>
  );

  /*
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));
  */
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));
  
  
  if(!(token && token.id)){
    return <Navigate replace to="/login" />;
  }
  
  const handleAddDir = () =>{
    
    //username,token_id,di
    //alert(`dir_path= ${dir_path}`);
    let  dir = inputdir;
    //alert(`468: dir = ${dir}`);
    if(!dir.startsWith('/')){
        if(pathh.path === '/'){
            dir = `/${dir}`;
        }
        else{
            dir = `${pathh.path}/${dir}`;
        }
    }
    //alert(`468: dir = ${dir}`);
    const post_data = {username:token.username, token:token.id, dir:dir};
    //alert(`467: post_data= ${JSON.stringify(post_data,null,4)}`);
 fetch(`${globals.api_root}/add-dir`,{
  method:"POST",
  headers: {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${token.id}`},
  body: JSON.stringify(post_data)
  })
  .then(response => response.json())
  .then(jsond => {
      
    //alert(`pathh.path= ${pathh.path} jsond= ${JSON.stringify(jsond,null,4)}`);
      
        if(jsond.dir){
            handleDirOnClick(pathh.path);
        }

  });


}


  const handleDirOnClick = (dir_path) =>{
      
      dir_path = dir_path.replace('//','/');
      //alert(`dir_path= ${dir_path}`);
      const post_data = JSON.stringify({username:token.username, token:token.id, dir:dir_path, page:page,rows_per_page:rowsPerPage})
      //alert(`684: post_data= ${JSON.stringify(post_data,null,4)}`);
   fetch(`${globals.api_root}/dir`,{
    method:"POST",
    headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.id}`},
    body:  post_data
    })
    .then(response => response.json())
    .then(json => {
        
        
        //setDirFiles(json.data);
        setFolder(json.data);
        setPathh({type:'folder', path:dir_path});
        //setIsLoaded(true);
    });


  }
 
  const handleNewPageOnClick = (dir_path,newpage) =>{
      
    dir_path = dir_path.replace('//','/');
    //alert(`dir_path= ${dir_path}`);
    const post_data = JSON.stringify({username:token.username, token:token.id, dir:dir_path, page:newpage,rows_per_page:rowsPerPage})
    //alert(`684: post_data= ${post_data}`);
 fetch(`${globals.api_root}/dir`,{
  method:"POST",
  headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token.id}`},
  body:  post_data
  })
  .then(response => response.json())
  .then(json => {
      
      
      //setDirFiles(json.data);
      setFolder(json.data);
      setPathh({type:'folder', path:dir_path});
      setPage(newpage);
      //setIsLoaded(true);
  });


}

  const handleOpenFileOrDirOnClick = (fd) =>{
    setPage(0);
    const fd_path = fd.url_path.replace('//','/')
    //fd_path = fd_path.replace('//','/');
    //alert(`dir_path= ${dir_path}`);
    if(fd.type === 'folder'){
        fetch(`${globals.api_root}/open`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token.id}`},
        //body: JSON.stringify({username:token.username, token:token.id, dir:fd_path})
        body: JSON.stringify({username:token.username, token:token.id, id:fd.id,page:page,rows_per_page:rowsPerPage })
        })
        .then(response => response.json())
        .then(json => {
            
            
            //setDirFiles(json.data);
            setFolder(json.data);
            setPathh({type:'folder', path:fd_path, id: fd.id, url_path: fd_path});
            //setIsLoaded(true);
        });
    }
    else if(fd.type === 'file'){
        //fetch(`${globals.api_root}/open`,{
        fetch(`${globals.api_root}/file`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token.id}`
    },
        //body: JSON.stringify({username:token.username, token:token.id, dir:fd_path})
        body: JSON.stringify({id:fd.id})
        })
        
        //.then(response => response.json())
        .then(response => {

            if (response.ok) {
                //alert('641:');
                return response.blob()
                    .then(function (myBlob) {
                        return {
                            blob: myBlob
                        };
                    });
            } else {
                //alert('649:');
                return response.json()
                    .then(function (myJson) {
                        return {
                            json: myJson
                        };
                    });
            }

        })
        .then(data => {
            
            if(data.blob){
                //alert('662');
                // Handle blob case
                const fileURL = URL.createObjectURL(data.blob);
                window.open(fileURL);

            }else{
                // Handle JSON case
            }
        }
        );
    }


}

const handleShareLinkOnClick = (fd) =>{
  
    const fd_path = fd.url_path.replace('//','/')
    //fd_path = fd_path.replace('//','/');
    //alert(`dir_path= ${dir_path}`);
    if(fd.type === 'file'){
        fetch(`${globals.api_root}/sharelink`,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token.id}`},
        //body: JSON.stringify({username:token.username, token:token.id, dir:fd_path})
        body: JSON.stringify({username:token.username, token:token.id, id:fd.id})
        })
        .then(response => response.json())
        .then(jsond => {
            
          setClipboardText(jsond.link);
          setIsOpenClipboardDialog(true);
            //setDirFiles(json.data);
            //setPathh({type:'folder', path:fd_path, id: fd.id, url_path: fd_path});
            //alert(`json.link= ${json.link}`)
            //navigator.clipboard.writeText(json.link);
            /*
            navigator.clipboard
                .writeText(jsond.link)
                .then(() => {
                  alert("Successfully copied");
                })
                .catch((e) => {
                  //alert(`something went wrong ${e}`);
                  setClipboardText(jsond.link)
                });
                      
                  });
                  */
                /*
                  setTimeout(async () => await navigator.clipboard.writeText("Your text")
                  .then(function () {
                    alert("Copy to clipboard successfully.");
                 }, function () {
                    alert("Copy to clipboard unsuccessfully.");
                 })
              );
              */

              //setTimeout(async () => await navigator.clipboard.writeText('hello'));

    
    })


  }
}

  const startUpload = () => {
      if(!(uploadedFiles.length > 0)){
        return;
      }

      setIsUploading(true)

    let error_message = [];
    

    let meta = {...inputsmeta};
    meta['parent_id'] = pathh.path;
    meta['parent_path'] =  pathh.id;

    
    let data = new FormData();
    data.append('refnum', meta.ref_num);
    data.append('claimnum', meta.claim_num);
    data.append('description', meta.description);
    data.append('dir_id', pathh.id);
    data.append('dir', pathh.path);
  
    

    uploadedFiles.map((f) => {
        //remaining.push(`${meta.path}/${f.originalname}`);
        data.append('attachments', f);
    });

   
    
    fetch(`${globals.upload_root}/uploads`,{
            method:'POST',
            headers:{'Authorization': `Bearer ${token.id}`},
            body: data,
    })
    .then(response => response.json())
        .then(jsond => {
            //alert(`jsond = ${JSON.stringify(jsond)}`);
            
            //setDirFiles(json.data);
            setIsUploading(false);
            toggleAddFileAccordion();
            
            handleDirOnClick(pathh.path);
            //setIsLoaded(true);
        });


  
  
    
  };

  


  const handleRemoveFileOrDir = async (item) => {
    

    const ret = await removeFileOrDir(item,token);

    handleDirOnClick(pathh.path)
      
      
};


  React.useEffect(() => {
    /*const ret_dirfiles = await getDirFiles(tokenn,'/')
    //alert(`89: ret_dirfiles= ${JSON.stringify(ret_dirfiles,null,4)}`)
    
    if(ret_dirfiles){
        setDirFiles(ret_dirfiles);
    }
    */
   
    //alert(`24: 119= ${JSON.stringify(pathh)}`)
    let url = pathh.type ==='folder' ? url_dir:url_fil
    //if(pathh.t == 'folder'){
      //alert(`965: page:${page}, rows_per_page:${rowsPerPage}`)
        
        
        
        fetch(url,{
            method:"POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token.id}`},
            body: JSON.stringify({username:token.username, token:token.id, dir:pathh.path,page:page,rows_per_page:rowsPerPage })
        })
        .then(response => response.json())
        .then(json => {
            
            
            //setDirFiles(json.data);
            setFolder(json.data)
            setIsLoaded(true);
        });
    //}
    //else{
        /*
        fetch(url,{
            method:"POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({username:tokenn.username, token:tokenn.id, fil:pathh.psth})
        })
        .then(response => response.json())
        .then(json => {
            
            
            setDirFiles(json.data);
            setIsLoaded(true);
        });
        */
    //}
    

    
    
  }, []);

  if(!isLoaded){
      return (
        <div>
            <p>Loading...</p>
        </div>
      )

      
  }


 
  
let paths = [];
if(pathh.path !== '/' && pathh.path !== ''){
    paths = pathh.path.split('/');
    //alert(`paths= ${JSON.stringify(paths)} paths.length= ${paths.length}`);
}
let ld = null;
if(paths.length === 0){
    
  
       ld = <Link key={'/'} onClick={() => handleDirOnClick('/')} underline="hover" color="inherit" href="#">
           {token.username}
        </Link>
        
}
else{
    let cpath = '';
    ld = paths?.map( (item) => {
        
        if(item === ''){
            cpath = `/`;
        }
        else{
            cpath = `${cpath}/${item}`;
        }

        const cpath1 = cpath.replace('//','/');
        
       
        
        return <Link key={cpath1} onClick={() => handleDirOnClick(cpath1)}  underline="hover" color="inherit" href="#">
            { cpath1==='/'? token.username : `${item}`}
      </Link>
        
    });
}

const anchor = "left";


return (
    
    
  <div>   
    <Box sx={{ flexGrow: 1 }}>
        
        <AppBar position="static">
        <Toolbar>
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={drawerState[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {listDrawerMenu(anchor)}
          </Drawer>
          </React.Fragment>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            PXC
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </Toolbar>
      </AppBar>

      
      <div role="presentation" >
      <Breadcrumbs aria-label="breadcrumb" p={2}>
          
          
        
        {ld}
        

      </Breadcrumbs>
      </div>
    
      
          {folder.items?.map( (item) => {
               
          return <Grid container spacing={2}>      
              <Grid item xs={4}>
              <Box sx={{ display: "flex" }} >
              <Stack direction="row" alignItems="center">
                
                <IconButton onClick={() => handleOpenFileOrDirOnClick({...item})}>
                    {item.type==='folder' ? <FolderIcon /> : <InsertDriveFileIcon/>}
                </IconButton>
                <Typography >{item.name}</Typography>
                </Stack>
            </Box>
            </Grid>
            
            <Grid item xs={2}>
            
            
          <IconButton
            id="basic-button"
            aria-controls={openFileMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openFileMenu ? 'true' : undefined}
            onClick={handleFileMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorElFileMenu}
            open={openFileMenu}
            onClose={handleFileMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {item.type === 'file' && 
              <MenuItem onClick={() => {handleFileMenuClose();handleShareLinkOnClick({...item})}}>
                  <ListItemIcon>
                    <ShareIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Copy Link</ListItemText>
              </MenuItem>
            }
            
            <MenuItem onClick={() => {handleFileMenuClose(); window.confirm("Confirm delete?") && handleRemoveFileOrDir({...item}) } }>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
            </MenuItem>
            
          </Menu>
    
            </Grid>
            </Grid>
            

      })}
      
      
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

      <TablePagination
      component="div"
      count={folder.count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
        
      <Accordion expanded={expandAddFolder}>
      <Box sx={{ display: "flex" }}>
        <AccordionSummary
        onClick={() => toggleAddFolderAccordion()}
         
        >
          <Chip icon={<AddIcon />} label="Folder" variant="outlined" />
        </AccordionSummary>
        
        </Box>
        <AccordionDetails spacing = {10}>
        <Stack spacing={2}>
            <TextField id="filled-basic" onChange = {(e) => setInputDir(e.target.value) } value = {inputdir} label="Folder name" variant="filled" /> 
            <FormControlLabel
            
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox name="ispublic" onChange={handleInputsDirChange}/>}
            label="Public"
          />
        
            <Button variant="outlined" onClick={ () => {handleAddDir(); toggleAddFolderAccordion(); }}>Submit</Button>
        </Stack>
        
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expandAddFile}>
      <Box sx={{ display: "flex" }}>
        <AccordionSummary
        onClick={() => toggleAddFileAccordion()}
          
        >
          {/*<Typography>Add new folder</Typography> */}
          
          <Chip icon={<AddIcon />} label="File" variant="outlined" />
          
          
        </AccordionSummary>
        
        </Box>
        <AccordionDetails spacing = {10}>
        
        
        
        {isUploading && 
          <Typography>Upload in progress... Please wait.</Typography>
        }

        {!isUploading &&
          <Grid container spacing={2}>
          <Grid item xs={4}>
          <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
              <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleFileEvent} />
              
              <Grid container container spacing={2}>
                  <Grid item xs={12} >
                  <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                  <div>
                  <p>Drag and drop your file here or</p>
                  
                  <Button variant="outlined" onClick={onButtonClick}>Select files </Button>
                  
                  
                  </div> 
              </label>
              { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }

                  </Grid>
                  <Grid item xs={12}>
                  <List>
              
              {uploadedFiles.map(file => (
                  <ListItem key={file.name}>
                      <ListItemText>
                      <Chip label={file.name} onDelete={ () => handleRemoveUploadedFiles(file)} />
                      
                      </ListItemText>
                      
                  </ListItem>
              ))} 
              
              </List>
                  </Grid>
                      
              </Grid>
                              
  
  
              </form>
          </Grid>
          <Grid item xs={8}>
              
              <Card>
              <Typography>Meta Data(optional)</Typography>
              <Grid content spacing={3} p={2}>
                  <Grid item xs={12} p={2}><TextField id="description" name= "description" onChange = {handleChangeMetaData} value = {inputsmeta.dscription} label="Description/Note" variant="filled" /> </Grid>
                  <Grid item xs={12} p={2}><TextField id="ref_num" name="ref_num" onChange = {handleChangeMetaData} value = {inputsmeta.ref_num} label="REF#" variant="filled" /></Grid>
                  <Grid item xs={12} p={2}><TextField id="claim_num" name="claim_num" onChange = {handleChangeMetaData} value = {inputsmeta.claim_num} label="CLAIM#" variant="filled" /></Grid>
                  <Grid item xs={12} p={2}><Button variant="outlined" onClick={startUpload}>Submit </Button></Grid>
              </Grid>
               
          
              </Card>
              
          </Grid>
          
          
      </Grid>
      
        }
        
        
        
        
       
        
        
       
        </AccordionDetails>
      </Accordion>
        
        
      </Paper>
    </Box>
    <Dialog
        open={isOpenClipboardDialog}
        onClose={handleCloseClipboardDialog}
        aria-labelledby="alert-dialog-clipboard"
        aria-describedby="alert-dialog-clipboard-description"
      >
        <DialogTitle id="alert-dialog-clipboard">
          {"Copy"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-clipboard-description">
            {clipboardText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClipboardDialog}>OK</Button>
          {/*<Button onClick={handleClose} autoFocus>
            Cancel
      </Button>*/}
        </DialogActions>
      </Dialog>
    
  </div>
    
    
	
	
);

    }
export default Home;
